// Generated by Framer (a417fcb)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/Axpol70OdRugqukuVIqY/fRCYwLdA8hEctMwccGMQ/IGFqizJ7S.js";
const CaretFonts = getFonts(Caret);

const cycleOrder = ["US8khra6a", "UygJ3Vpd0"];

const serializationHash = "framer-MXxN0"

const variantClassNames = {US8khra6a: "framer-v-133lxnj", UygJ3Vpd0: "framer-v-10w4b5k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "US8khra6a", Open: "UygJ3Vpd0"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Stream", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "US8khra6a"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "US8khra6a", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter170i0h9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (PWSlWeqS6) {const res = await PWSlWeqS6(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-133lxnj", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"US8khra6a"} onMouseEnter={onMouseEnter170i0h9} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} {...addPropertyOverrides({UygJ3Vpd0: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Product</motion.p></React.Fragment>} className={"framer-18n65w9"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"hbRl26BBQ"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", opacity: 1}} text={DApvjrdfG} variants={{UygJ3Vpd0: {opacity: 0.5}}} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-1r4dhq-container"} layoutDependency={layoutDependency} layoutId={"m3DtW6px5-container"}><Caret height={"100%"} id={"m3DtW6px5"} layoutId={"m3DtW6px5"} variant={"OVKxmcYcs"} width={"100%"} {...addPropertyOverrides({UygJ3Vpd0: {variant: "W1rfza3wq"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MXxN0.framer-7mc2pf, .framer-MXxN0 .framer-7mc2pf { display: block; }", ".framer-MXxN0.framer-133lxnj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-MXxN0 .framer-18n65w9 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-MXxN0 .framer-1r4dhq-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MXxN0.framer-133lxnj { gap: 0px; } .framer-MXxN0.framer-133lxnj > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-MXxN0.framer-133lxnj > :first-child { margin-left: 0px; } .framer-MXxN0.framer-133lxnj > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 69
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"UygJ3Vpd0":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFoQexUCOV: React.ComponentType<Props> = withCSS(Component, css, "framer-MXxN0") as typeof Component;
export default FramerFoQexUCOV;

FramerFoQexUCOV.displayName = "Trigger";

FramerFoQexUCOV.defaultProps = {height: 40, width: 69};

addPropertyControls(FramerFoQexUCOV, {variant: {options: ["US8khra6a", "UygJ3Vpd0"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Stream", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerFoQexUCOV, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}, ...CaretFonts], {supportsExplicitInterCodegen: true})